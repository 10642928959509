import Home from '../setContainer/Home/Home';
import AboutUs from '../setContainer/AboutUs/AboutUs';
import OurCompany from '../setContainer/OurCompany/OurCompany';
import Testimonial from '../setContainer/Testimonials/Testimonial';
import ContactUs from '../setContainer/ContactUs/ContactUs';
import Quote from '../setContainer/Quote/Quote';
import Career from '../setContainer/Career/Career';
import ff from '../setContainer/FixedFooter/FixedFooter';

// storing screen components inside array
export const TOTAL_SCREENS = [
  {
    screen_name: 'HOME',
    component: Home,
  },
  {
    screen_name: 'ABOUT US',
    component: AboutUs,
  },
  {
    screen_name: 'OUR COMPANY',
    component: OurCompany,
  },
  {
    screen_name: 'QUOTE',
    component: Quote,
  },
  {
    screen_name: 'TESTIMONIAL',
    component: Testimonial,
  },
  {
    screen_name: 'CAREER',
    component: Career,
  },
  {
    screen_name: 'CONTACT US',
    component: ContactUs,
  },
  {
    screen_name: '',
    component: ff,
  },
];

//getting index of the screen being rendered stored inside the array TOTAL_SCREENS[]
export const GET_SCREEN_INDEX = (screen_name) => {
  if (!screen_name) return -1;
  for (let i = 0; i < TOTAL_SCREENS.length; i++) {
    if (TOTAL_SCREENS[i].screen_name === screen_name) return i;
  }
  return -1;
};
