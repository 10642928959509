import React from 'react';
import './FixedFooter.css';
// import ScrollService from "../../utilities/ScrollService";
export default function FixedFooter() {
  return (
    <div className="fixed-footer-container">
      <div className="fixed-footer-parent">
        <span className="fixed-footer-text">
          1380 Wall St, Winnipeg, Manitoba, Canada R3E 2R9 | Ph: 431-588-7384
        </span>
      </div>
    </div>
  );
}
