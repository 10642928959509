import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './Testimonial.css';

import ScreenHeading from '../../utilities/ScreenHeading/ScreenHeading';
import ScrollService from '../../utilities/ScrollService';
import Animations from '../../utilities/Animations';
import shape from '../../../src/assets/Testimonial/shape-bg.png';
// import Footer from "../../setContainer/Footer/Footer";
// import shape1 from "../../../src/assets/Testimonial/shape-bg-header.png";

export default function Testimonial(props) {
  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;

    Animations.animations.fadeInScreen(props.id);
  };

  const fadeInSubscription =
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  // OWL CAROUSEL
  const options = {
    loop: true,
    margin: 0,
    nav: true,
    animateIn: 'bounceInRight',
    animateOut: 'bounctOutRight',
    dots: true,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      // smaller device its gonna show one column
      0: {
        items: 1,
      },
      // medium devices gonna shoe one column
      768: {
        items: 1,
      },
      // big screens gonna show 3 columns
      1000: {
        items: 3,
      },
    },
  };

  return (
    <div className="fade-in" id={props.id || ''}>
      <ScreenHeading
        title={'Testimonial'}
        subHeading={'What Our Clients Think About Us'}
      ></ScreenHeading>
      <section className="testimonial-section">
        <div className="container">
          <div className="row">
            <OwlCarousel
              className="owl-carousel"
              id="testimonial-carousel"
              {...options}
            >
              {/* FIRST TESTIMONIAL */}
              <div className="col-lg-12">
                <div className="testi-item">
                  <div className="testi-comment">
                    <p style={{ fontSize: '12px', lineHeight: '1.3' }}>
                      <i className="fa fa-quote-left" />
                      We have been using S.E.T. for the last five years and they
                      do an excellent job in updating and keeping track of each
                      shipment. SET Group has an impeccable safety record,
                      adhering to all necessary regulations and ensuring the
                      well-being of their drivers and the general public. Their
                      commitment to maintaining high standards of safety and
                      compliance makes them an ideal carrier in our pool of
                      transport providers.
                      <i className="fa fa-quote-right" />
                    </p>
                    <ul className="stars list-unstyled">
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                    </ul>
                  </div>
                  <div className="client-info">
                    <img
                      src="img/Testimonial/mlf.png"
                      alt="no internet connection"
                    ></img>
                    <h5>Tahir Choudhary | MLF</h5>
                    <p style={{ fontSize: '10.5px' }}>
                      Director of International Transportation
                    </p>
                  </div>
                </div>
              </div>
              {/* SECOND TESTIMONIAL  */}
              <div className="col-lg-12">
                <div className="testi-item">
                  <div className="testi-comment">
                    <p style={{ fontSize: '12px', lineHeight: '1.3' }}>
                      <i className="fa fa-quote-left" />
                      Randy Brar has provided us with reliable and safe
                      transport of our foods since 2008.
                      <br /> These concerns quickly vanished as it was very
                      evident Randy's commitment to his customers, his team, and
                      safety culture was paramount for a successful business.
                      Still our primary carrier to this day, Simply Efficient
                      Transport continues to support our Bakery 24/7 with
                      dependable service for our production lines and customers.
                      <i className="fa fa-quote-right" />
                    </p>
                    <ul className="stars list-unstyled">
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                    </ul>
                  </div>
                  <div className="client-info">
                    <img
                      src="img/Testimonial/asp.png"
                      alt="no internet connection"
                    ></img>
                    <h5>Jason LaRocque | ASP</h5>
                    <p style={{ fontSize: '10.5px' }}>Logistics Manager</p>
                  </div>
                </div>
              </div>

              {/* THIRD TESTIMONIAL  */}
              <div className="col-lg-12">
                <div className="testi-item">
                  <div className="testi-comment">
                    <p style={{ fontSize: '12px', lineHeight: '1.3' }}>
                      <i className="fa fa-quote-left" />
                      S.E.T. have been a valued service provider and we see no
                      reason as to why our relationship will not continue. It
                      must be recognized that S.E.T. has had zero claims,
                      damages or incidents in our ten (10) years of continued
                      service excellence. Randy has exceeded our customers'
                      expectations and I highly recommend him to any company
                      seeking excellence through reliable, courteous and
                      consistent transport service.
                      <i className="fa fa-quote-right" />
                    </p>
                    <ul className="stars list-unstyled">
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                    </ul>
                  </div>
                  <div className="client-info">
                    <img
                      src="img/Testimonial/tar.png"
                      alt="no internet connection"
                    ></img>
                    <h5>Mike Bellisle | TAR</h5>
                    <p style={{ fontSize: '10.5px' }}>Plant Manager</p>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </section>
      <div className="footer-image">
        <img src={shape} alt="something went wrong" />
      </div>
      {/* <Footer/> */}
    </div>
  );
}
