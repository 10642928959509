import React from 'react';
import './Footer.css';

export default function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-parent">
        {/* Curved Shaped white stripe */}
        {/* <img src={require ('../../../assets/Home/shape-bg.png')} alt='no internet connection'></img> */}
      </div>
    </div>
  );
}
