import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import load1 from "../../../src/images/load2.gif";
import "./Career.css";


export default function AboutUs(props) {
  { //Animation
  //Fade In Animation
  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };
  const fadeInSubscription =
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);
  }

  ///FORM COMPONENTS
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [resume, setSelectedFile] = useState(null);
  const [banner, setBanner] = useState("");
  const [bool, setBool] = useState(false);

  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);

  const handleName = (e) => {
    setName(e.target.value);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  }
  
  const submitForm = async (e) => {
    e.preventDefault();
    try {
      // let data = {
      //   name,
      //   email,
      //   resume,
      // };

      const data = new FormData();
      data.append("name", name);
      data.append("email", email);
      data.append("resume", resume);

      setBool(true);
      const res = await axios.post(`/career`, data);

      const reEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
      if (name.length === 0 || email.length === 0 || !reEmail.test(email)) {
        setBanner(res.data.msg);
        toast.error(res.data.msg);
        setBool(false);
      } 
      else if (res.status === 200) {
        setBanner(res.data.msg);
        toast.success(res.data.msg);
        setBool(false);
        setName("");
        setEmail("");
        setSelectedFile();
        setBanner("");
      }

    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      className="career-container screen-container fade-in"
      id={props.id || ""}
    >
      <div className="career-parent">
        {/* setting title attribute and sub-heading attribute */}
        <ScreenHeading
          title={"Career"}
          subHeading={"Interested In a New Career?"}
        />
        <div className="career-card">
          <div className="career-profile"></div>
          <div className="career-details">
            <span className="career-description">
            Thank you for your interest in joining our team at Set Tranport!<br/>At Set Transport, we are always seeking new long haul drivers to join our team of professionals.  We are also seeking owner operators for pulling our substantial fleet of dry vans, refers & heaters across Canada.<br/>Please fill in your personal information below and attach your resume.<br/>Thank you again for your interest in Set Transport.  You will hear from us soon.  Please allow 10 working days prior to contacting us.
            </span>
            <div className="career-highlights">
              <div className="highlight-heading">
                <form onSubmit={submitForm} encType="multipart/form-data">
                  <p>{banner}</p> 
                  <label htmlFor="name">Name</label>
                  <input type="text" onChange={handleName} value={name} />

                  <label htmlFor="email">Email</label>
                  <input type="email" onChange={handleEmail} value={email} />

                  <label htmlFor="resume">Upload Resume</label>
                  <input type="file"
                  name="resume"
                  accept=".pdf"
                  onChange={handleFileSelect}
                  />

                  <div className="send-btn">
                    <button type="submit">
                      {bool ? (
                        <b className="load">
                          <img src={load1} alt="Loading not responding" />
                        </b>
                      ) : (
                        <i className="fa fa-paper-plane" />
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="career-options">
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


// import React from "react";
// import axios from "axios";

// class App extends React.Component {
// state = {
// 	files: null,
// };

// handleFile(e) {
// 	// Getting the files from the input
// 	let files = e.target.files;
// 	this.setState({ files });
// }

// handleUpload(e) {
// 	let files = this.state.files;

// 	let formData = new FormData();

// 	//Adding files to the formdata
// 	formData.append("image", files);
// 	formData.append("name", "Name");

// 	axios({
// 	// Endpoint to send files
// 	url: "/career",
// 	method: "POST",
// 	headers: {
// 		// Add any auth token here
// 		authorization: "your token comes here",
// 	},
// 	// Attaching the form data
// 	data: formData,
// 	})
// 	.then((res) => { }) // Handle the response from backend here
// 	.catch((err) => { }); // Catch errors if any
// }

// render() {
// 	return (
// 	<div>
// 		<h1>Select your files</h1>
// 		<input
// 		type="file"
// 		multiple="multiple" //To select multiple files
// 		onChange={(e) => this.handleFile(e)}
// 		/>
// 		<button onClick={(e) => this.handleUpload(e)}
// 		>Send Files</button>
// 	</div>
// 	);
// }
// }

// export default App;
