import React, { useState } from "react";
import Typical from "react-typical";
import axios from "axios";
import { toast } from "react-toastify";

import imgBack from "../../../src/images/mailz.jpeg";
import load1 from "../../../src/images/load2.gif";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import Footer from "../../setContainer/Footer/Footer";
import FixedFooter from "../../setContainer/FixedFooter/FixedFooter";
import "./ContactUs.css";

export default function ContactMe(props) {
  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };

  const fadeInSubscription =
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [banner, setBanner] = useState("");
  const [bool, setBool] = useState(false);

  const handleName = (e) => {
    setName(e.target.value);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleMessage = (e) => {
    setMessage(e.target.value);
  };
  console.log(message);
    const submitForm = async (e) => {
      e.preventDefault();
      try {
        let data = {
          name,
          email,
          message,
        };
        // console.log(data);
        setBool(true);
        const res = await axios.post(`/contact`, data);

        const reEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;        
        if (name.length === 0 || email.length === 0 || message.length === 0 || !reEmail.test(email)) {
          setBanner(res.data.msg);
          toast.error(res.data.msg);
          setBool(false);
        } else if (res.status === 200) {
          setBanner(res.data.msg);
          toast.success(res.data.msg);
          setBool(false);

          setName("");
          setEmail("");
          setMessage("");
          setBanner("");
        }
      } catch (error) {
        console.log(error);
      }
    };

  return (
    <div className="cu-main-container fade-in" id={props.id || ""}>
      <ScreenHeading subHeading={"Get In Touch With Us"} title={"Contact Us"} />
      <div className="central-form">
        <div className="col">
          <h2 className="title">
            <Typical loop={Infinity} steps={["Get In Touch 📧", 1000]} />
          </h2>{" "}
          <a href="#">
            <i className="fa fa-facebook-square" />
          </a>
          <a href="#">
            <i className="fa fa-google-plus-square" />
          </a>
          <a href="#">
            <i className="fa fa-instagram" />
          </a>
          <a href="#">
            <i className="fa fa-youtube-square" />
          </a>
          <a href="#">
            <i className="fa fa-twitter" />
          </a>
        </div>
        <div className="back-form">
          <div className="img-back">
            <h4>Send Your Queries Here!</h4>
            <img src={imgBack} alt="imagee not found" />
          </div>
          <form onSubmit={submitForm}>
            <p>{banner}</p>
            <label htmlFor="name">Name</label>
            <input type="text" onChange={handleName} value={name} />

            <label htmlFor="email">Email</label>
            <input type="email" onChange={handleEmail} value={email} />

            <label htmlFor="message">Message</label>
            <textarea type="text" onChange={handleMessage} value={message} />

            <div className="send-btn">
              <button type="submit">
                {bool ? (
                  <b className="load">
                    <img src={load1} alt="image not responding" />
                  </b>
                ) : (
                  <i className="fa fa-paper-plane" />
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}
