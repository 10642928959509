import React, { useEffect } from 'react';
import ScreenHeading from '../../utilities/ScreenHeading/ScreenHeading';
import ScrollService from '../../utilities/ScrollService';
import Animations from '../../utilities/Animations';
import './AboutUs.css';

export default function AboutUs(props) {
  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };

  const fadeInSubscription =
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  const SCREEN_CONSTSANTS = {
    description: ``,
    highlights: {
      bullets: [
        'Local Fleet – 100 %, Hand Picked and Professionally Trained, Owner Operator Fleet.',
        'Fleet Capacity – 1 to 20 Trucks (As Per Business Demand)',
        'Trailers or Chassis – Procured On-Demand Basis',
        'Yard Capacity – Yard Space Is Available to Spot 10 to 50 Trailers',
        'Service Timing – 24 Hours / 365',
      ],
      heading: 'NICHE TRANSPORTATION SERVICES HIGHLIGHTS',
    },
  };
  const renderHighlight = () => {
    return SCREEN_CONSTSANTS.highlights.bullets.map((value, i) => (
      <div className="highlight" key={i}>
        <div className="highlight-blob"></div>
        <span>{value}</span>
      </div>
    ));
  };

  return (
    <div
      className="about-us-container screen-container fade-in"
      id={props.id || ''}
    >
      <div className="about-us-parent">
        {/* setting title attribute and sub-heading attribute */}
        <ScreenHeading title={'About Us'} subHeading={'Why Choose Us?'} />
        <div className="about-us-card">
          <div className="about-us-profile"></div>
          <div className="about-us-details">
            <span className="about-us-description">
              <b>Simply Efficient Transport Group (SET Group)</b> - The
              evolution of Simply Efficient Transport Group started in the year
              2002, with a sincere ambition to achieve long-term business
              success in the transportation industry. The current business model
              of our services in the transportation industry is built on 20
              years of professional experience that is earned around a
              relentless desire to learn to create a successful transportation
              business.
              <br /> The hallmark of our evolutionary journey is 4 years of
              research and development (2013-17), dedicated to understanding the
              prevailing challenges of the transportation industry. We evaluated
              the challenges against the fundamental principles of business
              success and created a framework of innovative and effective
              operating strategies those are essentially required to operate a
              successful and profitable transportation business in the 21st
              century business environment. Our diligent efforts lead to the
              establishment of SET Group - Offering business and workforce
              development programs to MSM entities of Canada's trucking industry
              - By Thinking Outside of The Box!
            </span>
            <div className="about-us-highlights">
              <div className="highlight-heading">
                <span style={{ color: 'green' }}>
                  <b>{SCREEN_CONSTSANTS.highlights.heading}</b>
                </span>
              </div>
              {renderHighlight()}
            </div>
            <div className="about-us-options">
              <button
                className="btn primary-btn"
                onClick={() => ScrollService.scrollHandler.scrollToOurCompany()}
              >
                {' '}
                More Info{' '}
              </button>
              <button
                className="btn highlighted-btn"
                onClick={() => ScrollService.scrollHandler.scrollToQuote()}
              >
                Get a Quote
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
