import React, { useState, useEffect } from 'react';
import ScreenHeading from '../../utilities/ScreenHeading/ScreenHeading';
import ScrollService from '../../utilities/ScrollService';
import Animations from '../../utilities/Animations';
import ManagementPicture from '../../assets/OurCompany/profile.jpg';
// import Footer from "../Footer/Footer";
import './OurCompany.css';

export default function OurCompany(props) {
  const [selectedBulletIndex, setSelectedBulletIndex] = useState(0);
  const [carousalOffsetStyle, setCarousalOffsetStyle] = useState({});

  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;

    Animations.animations.fadeInScreen(props.id);
  };

  const fadeInSubscription =
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  const OurCompanyHeading = (props) => {
    return (
      <div className="ourCompany-heading">
        <div className="ourCompany-main-heading">
          <div className="heading-bullet"></div>
          <span>{props.heading ? props.heading : ''}</span>
          <div className="ourCompany-sub-heading">
            <span>{props.subHeading ? props.subHeading : ''}</span>
          </div>
          <div className="ourCompany-heading-description">
            <span>{props.description ? props.description : ''}</span>
          </div>
        </div>
      </div>
    );
  };

  const ourCompanyBullets = [
    { label: 'Simply Ambitious', logoSrc: 'Simple.svg' },
    { label: 'Efficient Vision', logoSrc: 'Efficient.svg' },
    { label: 'Trustworthy Commitment', logoSrc: 'Trustworthy.svg' },
    { label: 'Niche Transportation Services', logoSrc: 'service.svg' },
    { label: 'Experience', logoSrc: 'experience.svg' },
    { label: 'Analytic', logoSrc: 'Analytic.svg' },
    { label: 'Meet our CEO', logoSrc: 'management.png' },

    // { label: 'Customer Center', logoSrc: 'download.svg' },
  ];

  const analyticDetails = [
    { skills: 'Friendly Environment', ratingPercentage: 100 },
    { skills: 'Client Stisfaction', ratingPercentage: 100 },
    { skills: 'Trust', ratingPercentage: 100 },
    { skills: 'Respect', ratingPercentage: 100 },
    { skills: 'Team Work', ratingPercentage: 100 },
    { skills: 'Discipline', ratingPercentage: 100 },
  ];

  const ourCompanyDetails = [
    //SIMPLE
    <div className="ourCompany-screen-container" key="simple">
      <div className="experience-container">
        <OurCompanyHeading heading={'Our Ambition'} subHeading={''} />
        <div className="experience-description">
          <span className="ourCompany-description-text">
            We know frost hand that transportation is a challenging business.
            Our business ownership experience spanning 21 years made us realize
            that majority of the trucking industry professionals - the business
            owners, the managers, the truck driving workforce and the consumers
            of services - are not really happy with the challenging business
            environment of trucking sector. We strive to do everything in our
            professional capacities to make transport industry professionals
            “Happy”. It is our aim, our ambition, and the founding principle of
            our mission.
          </span>
        </div>
      </div>
    </div>,

    //EFFICIENT
    <div className="ourCompany-screen-container" key="efficient">
      <div className="experience-container">
        <OurCompanyHeading heading={'Our Vision'} subHeading={''} />
        <div className="experience-description">
          <span className="ourCompany-description-text">
            We are confident to realize our vision. We Truly believe that
            challenges are only opportunities in disguise; the challenging
            business environment of transportation industry presented a valuable
            opportunity to find the ways of “creating happy transport industry
            professionals”. We know that “happiness” is subjective to personal
            success that comes from professional success, therefore we focused
            our strategy on developing resources to ensure professional success
            of the transport industry professionals. Our knowledge, experience
            and commitment to the cause gave us confidence to embark on an
            arduous, but joyously adventurous, journey of 5 years to find
            innovative solutions for the challenges of the 21st century
            transport industry.
          </span>
        </div>
      </div>
    </div>,

    //TRUSTWORTHY
    <div className="ourCompany-screen-container" key="trustworthy">
      <div className="experience-container">
        <OurCompanyHeading
          heading={'Our Creative Commitment'}
          subHeading={''}
        />
        <div className="experience-description">
          <span className="ourCompany-description-text">
            We are relators, we are learners, we adapt and we respond with
            innovative creativity. We also know creativity is sometimes elusive!
            It requires something beyond passion, and we believe that is
            obsession. Our obsession fueled 5 year journey - 2013-2018 - of
            relentless efforts in researching and developing, creating and
            testing, persevering through the trying phases, improving and
            retesting and never giving up until we found the most effective
            solutions. That is the how we created the professional services
            platform of Simply Efficient Transport Group to serve the needs of
            21st century transportation industry.
          </span>
        </div>
      </div>
    </div>,

    //NICHE SERVICES
    <div className="ourCompany-screen-container" key="simple">
      <div className="experience-container">
        {/* <OurCompanyHeading heading={"Our Ambition"} subHeading={""} /> */}
        <div className="experience-description">
          <span className="ourCompany-description-text">
            <b>Simply Efficient Transport and Logistics Inc.</b> is an
            integrated transportation services division of SET Group. We offer
            asset based and non-asset-based transportation solutions for a small
            group of our customers.
            <br />
            <span style={{ color: 'green', fontWeight: 'bold' }}>
              Asset Based Fleet - Winnipeg, Manitoba Area and Intra-State
              Manitoba
            </span>
            <br />
            In 2013, we choose to develop a{' '}
            <b>
              <u>Strengths Based</u>
            </b>{' '}
            operating strategy that focuses on specializing in offering
            transportation services in the Winnipeg area and intra state
            Manitoba. Our fleet consists of 100% owner operators, those who are
            professionally trained to service the requirements of a small
            network of selected niche customers.
          </span>
        </div>
      </div>
    </div>,
    // EXPERIENCE
    <div className="ourCompany-screen-container" key="simple">
      <div className="experience-container">
        {/* <OurCompanyHeading heading={'Our Ambition'} subHeading={''} /> */}
        <div className="experience-description">
          <span className="ourCompany-description-text">
            <span style={{ fontSize: '15px' }}>
              <b>
                20 Years of Experience in the transport industry of North
                America.
              </b>
            </span>
            <br />
            <div className="highlight">
              <div className="highlight-blob"></div>
              <span>
                15 Years Experience of operating intra-province Manitoba -
                2002-07 and 2013-Current
              </span>
            </div>

            <div className="highlight">
              <div className="highlight-blob"></div>
              <span>
                6 Years Experience (2007 - 2013) Operating National Fleet - SET
                Transport Inc.
              </span>
            </div>
            <div className="highlight">
              <div className="highlight-blob"></div>
              <span>
                Serving Canada's renowned food industry customers with HACCP
                Compliant Refrigerated Trailers.
              </span>
            </div>
            <ul className="no-bullet">
              <li>
                <div className="highlight">
                  <span>
                    Aspire Bakeries International (Formerly Gourmet Bakers
                    Canada) - <i>2007 to Current</i>
                  </span>
                </div>
              </li>
              <li>
                <div className="highlight">
                  <span>
                    Maple Leaf Foods Canada Inc - <i>2018- Current</i>
                  </span>
                </div>
              </li>
              <li>
                <div className="highlight">
                  <span>
                    Canada Safeway (Acquired by Sobeys's) - <i>2009-2013</i>
                  </span>
                </div>
              </li>
              <li>
                <div className="highlight">
                  <span>
                    Walmart Canada Corporation - <i>2011-2013</i>
                  </span>
                </div>
              </li>
            </ul>
          </span>
        </div>
      </div>
    </div>,

    //ANALYTICAL
    <div
      className="ourCompany-screen-container analytical-skills-container"
      key="analytical-skills"
    >
      {analyticDetails.map((skill, index) => (
        <div className="skill-parent" key={index}>
          <div className="heading-bullet"></div>
          <span>{skill.skills}</span>
          <div className="skill-percentage">
            <div
              style={{ width: skill.ratingPercentage + '%' }}
              className="active-percentage-bar"
            ></div>
          </div>
        </div>
      ))}
    </div>,
    //MANAGEMENT
    <div className="ourCompany-screen-container" key="management">
      <div className="experience-container">
        <OurCompanyHeading heading={'Meet the CEO'} subHeading={''} />
        <div className="experience-description">
          <span className="ourCompany-description-text">
            My name is Randy Brar. I am a proud transport industry professional
            and I am privileged to shoulder the responsibility of leading Simply
            Efficient Transport Group as the CEO.
            <span className="ourCompany-description-text">
              <img src={ManagementPicture} alt="no internet connection"></img>
            </span>
            I was introduced to transportation industry as a career option in
            August 2002 and I must say that in the last 21 years I have enjoyed
            every moment of working in this truly great profession. I feel proud
            of every person associated with the transportation industry and I
            salute the brave hearts of my industry those who work day and night,
            in rain or shine, often sacrificing personal comfort and leaving
            families behind to earn an honest livelihood.
          </span>
        </div>
      </div>
      <br />
    </div>,
  ];

  const handleCarousal = (index) => {
    let offsetHeight = 360;

    let newCarousalOffset = {
      style: { transform: 'translateY(' + index * offsetHeight * -1 + 'px)' },
    };

    setCarousalOffsetStyle(newCarousalOffset);
    setSelectedBulletIndex(index);
  };

  const getBullets = () => {
    return ourCompanyBullets.map((bullet, index) => (
      <div
        onClick={() => handleCarousal(index)}
        className={
          index === selectedBulletIndex ? 'bullet selected-bullet' : 'bullet'
        }
        key={index}
      >
        <img
          className="bullet-logo"
          src={require(`../../assets/OurCompany/${bullet.logoSrc}`)}
          alt="B"
        />
        <span className="bullet-label">{bullet.label}</span>
      </div>
    ));
  };

  const getOurCompanyScreens = () => {
    return (
      <div
        style={carousalOffsetStyle.style}
        className="ourCompany-details-carousal"
      >
        {ourCompanyDetails.map((ourCompanyDetail) => ourCompanyDetail)}
      </div>
    );
  };

  useEffect(() => {
    return () => {
      /* UNSUBSCRIBE THE SUBSCRIPTIONS */
      fadeInSubscription.unsubscribe();
    };
  }, [fadeInSubscription]);

  return (
    <div
      className="ourCompany-container screen-container fade-in"
      id={props.id || ''}
    >
      <div className="ourCompany-content">
        <ScreenHeading
          title={'Our Company'}
          subHeading={'Detailed Information about our Company'}
        />
        <div className="ourCompany-card">
          <div className="ourCompany-bullets">
            <div className="bullet-container">
              <div className="bullet-icons"></div>
              <div className="bullets">{getBullets()}</div>
            </div>
          </div>
          <div className="ourCompany-bullet-details">
            {getOurCompanyScreens()}
          </div>
        </div>
        {/* <Footer/> */}
      </div>
    </div>
  );
}
