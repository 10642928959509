import React, { useState } from 'react';
import Typical from 'react-typical';
import axios from 'axios';
import { toast } from 'react-toastify';

import imgBack from '../../../src/images/mailz.jpeg';
import load1 from '../../../src/images/load2.gif';
import ScreenHeading from '../../utilities/ScreenHeading/ScreenHeading';
import ScrollService from '../../utilities/ScrollService';
import Animations from '../../utilities/Animations';
// import Footer from "../../setContainer/Footer/Footer";
import './Quote.css';

export default function ContactMe(props) {
  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };

  const fadeInSubscription =
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [quote, setQuote] = useState('');
  const [city, setCity] = useState('');
  const [postal, setPostal] = useState('');
  const [company, setCompany] = useState('');
  const [telephone, setTelephone] = useState('');
  const [banner, setBanner] = useState('');
  const [bool, setBool] = useState(false);

  const handleName = (e) => {
    setName(e.target.value);
  };
  const handleCompany = (e) => {
    setCompany(e.target.value);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleTelephone = (e) => {
    setTelephone(e.target.value);
  };
  const handleCity = (e) => {
    setCity(e.target.value);
  };
  const handlePostal = (e) => {
    setPostal(e.target.value);
  };
  const handleQuote = (e) => {
    setQuote(e.target.value);
  };
  console.log(quote);
  const submitForm = async (e) => {
    e.preventDefault();
    try {
      let data = {
        name,
        email,
        quote,
        telephone,
        city,
        company,
        postal,
      };
      console.log(data);
      setBool(true);

      const res = await axios.post(`/quote`, data);
      const rePhone =
        /^[+]?[0-1]?[\s]?[(]?[0-9]{3}[)]?[\s-]?[0-9]{3}[\s-]?[0-9]{4}$/;
      const rePostal =
        /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
      const reEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;

      if (
        name.length === 0 ||
        email.length === 0 ||
        quote.length === 0 ||
        telephone.length === 0 ||
        company.length === 0 ||
        !rePhone.test(telephone) ||
        !rePostal.test(postal) ||
        !reEmail.test(email)
      ) {
        setBanner(res.data.msg);
        toast.error(res.data.msg);
        setBool(false);
      } else if (res.status === 200) {
        setBanner(res.data.msg);
        toast.success(res.data.msg);
        setBool(false);

        setName('');
        setEmail('');
        setQuote('');
        setTelephone('');
        setCity('');
        setCompany('');
        setPostal('');
        setBanner('');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="quote-main-container fade-in" id={props.id || ''}>
      <ScreenHeading
        subHeading={'Get A Cost-Effective Quote For The Load'}
        title={'Request Quote'}
      />
      <div className="central-form-quote">
        <div className="col">
          <h2 className="title">
            <Typical
              loop={Infinity}
              steps={['Please fill out the form 📧', 1000]}
            />
          </h2>{' '}
          <a href="#">
            <i className="fa fa-facebook-square" />
          </a>
          <a href="#">
            <i className="fa fa-google-plus-square" />
          </a>
          <a href="#">
            <i className="fa fa-instagram" />
          </a>
          <a href="#">
            <i className="fa fa-youtube-square" />
          </a>
          <a href="#">
            <i className="fa fa-twitter" />
          </a>
        </div>
        <div className="back-form">
          <div className="img-back">
            <h4>Request a quote here!</h4>
            <img src={imgBack} alt="imagee not found" />
          </div>
          <form onSubmit={submitForm}>
            <p>{banner}</p>
            <label htmlFor="name">Name</label>
            <input type="text" onChange={handleName} value={name} />

            <label htmlFor="company">Company</label>
            <input type="text" onChange={handleCompany} value={company} />

            <label htmlFor="email">Email</label>
            <input type="text" onChange={handleEmail} value={email} />

            <label htmlFor="telephone">Telephone</label>
            <input type="tel" onChange={handleTelephone} value={telephone} />
            {/* <PhoneInput
            defaultCountry="US"
      placeholder="Enter phone number"
      value={telephone}
      onChange={handleTelephone}/> */}

            <label htmlFor="city">City</label>
            <input type="text" onChange={handleCity} value={city} />

            <label htmlFor="postal">Postal Code</label>
            <input type="text" onChange={handlePostal} value={postal} />

            <label htmlFor="quote">What would you like a quote for?</label>
            <textarea type="text" onChange={handleQuote} value={quote} />

            <div className="send-btn">
              <button type="submit">
                {bool ? (
                  <b className="load">
                    <img src={load1} alt="Not Responding" />
                  </b>
                ) : (
                  <i className="fa fa-paper-plane" />
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}
