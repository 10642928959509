import React from 'react';
import Typical from 'react-typical';
import ScrollService from '../../../utilities/ScrollService';
import './Main.css';

export default function Main() {
  return (
    <div className="main-container">
      <div className="main-parent">
        <div className="main-details">
          <div className="colz">
            <div className="cols-icon">
              {/* BOOTSTRAP SOCIAL MEDIA ICONS */}
              <a href="//">
                <i className="fa fa-facebook-square"></i>
              </a>
              <a href="https://www.google.com/search?q=simply+efficient+transport&sxsrf=AB5stBgyqzxaMaJwTRaUcfSUB-v8xzyC-A%3A1690994887167&ei=x4jKZO3fCePB0PEP0L-4yAg&oq=Simply+ef&gs_lp=Egxnd3Mtd2l6LXNlcnAiCVNpbXBseSBlZioCCAAyBxAjGIoFGCcyBRAAGIAEMgUQABiABDIFEAAYgAQyBRAAGIAEMgUQABiABDIFEAAYgAQyBRAAGIAEMgUQABiABDIFEAAYgARItz1Q9AxYxzRwA3gAkAEAmAGWAaABgAmqAQMxLji4AQHIAQD4AQHCAgsQABiKBRiGAxiwA8ICBBAjGCfCAgcQLhiKBRhDwgIIEAAYigUYkQLCAg0QLhiKBRjHARjRAxhDwgIKEC4YigUYsQMYQ8ICEBAAGIAEGBQYhwIYsQMYgwHCAg0QABiKBRixAxiDARhDwgIKEAAYgAQYFBiHAsICBxAAGIoFGEPCAgsQABiABBixAxiDAcICCBAuGIAEGLEDwgIEEAAYA8ICDRAAGIAEGBQYhwIYsQPCAgsQLhiABBjHARivAcICERAuGIoFGLEDGIMBGMcBGK8BwgIREC4YgAQYsQMYgwEYxwEY0QPCAhEQLhiKBRixAxiDARjHARjRA8ICCBAAGIAEGLEDwgIIEAAYigUYsQPCAgsQLhiABBjHARjRA-IDBBgBIEGIBgGQBgM&sclient=gws-wiz-serp">
                <i className="fa fa-google-plus-square"></i>
              </a>
              <a href="//">
                <i className="fa fa-instagram"></i>
              </a>
              <a href="//">
                <i className="fa fa-youtube-square"></i>
              </a>
              <a href="//">
                <i className="fa fa-twitter"></i>
              </a>
            </div>
          </div>

          {/* Main Name */}
          <div className="main-details-name">
            <i>
              <span className="primary-text">
                {' '}
                <span className="highlighted-text">
                  Simply Efficient Transport
                </span>{' '}
                Group
              </span>
            </i>
          </div>

          <div className="main-details-role">
            {/* MOVING TEXT */}
            <span className="primary-text">
              {' '}
              <h1>
                <i>
                  <Typical
                    loop={Infinity}
                    steps={[
                      'SAFE',
                      1500,
                      'EFFICIENT',
                      1500,
                      'TRUSTWORTHY',
                      1500,
                      'PROFESSIONALS',
                      1500,
                    ]}
                  />
                </i>
              </h1>
              {/* DESCRIPTION TEXT */}
              <i>
                <span className="main-role-tagline">
                  Safe, Efficient, and Trustworthy Professionals.
                </span>
              </i>
            </span>
          </div>

          {/* Buttons */}
          <div className="main-options">
            <button
              className="btn primary-btn"
              onClick={() => ScrollService.scrollHandler.scrollToAboutUs()}
            >
              {''}
              About Us{' '}
            </button>
            {/* <button
              className="btn highlighted-btn"
              onClick={() => ScrollService.scrollHandler.scrollToQuote()}
            >
              {''}
              SET Technologies{' '}
            </button> */}
            <a href="https://settech.ca/" target="_blank" rel="noreferrer">
              <button className="btn highlighted-btn">
                S.E.T. Technologies
              </button>
            </a>
          </div>
        </div>

        {/* Company Logo */}
        <div className="profile-picture">
          <div className="profile-picture-background"></div>
        </div>
      </div>
    </div>
  );
}
