import React from "react";
import "./ScreenHeading.css";

export default function ScreenHeading(props) {
  return (
    // the container above screen sectoins - including :- Section heading, a small desc, small horizontal bar
    <div className="heading-container">
      {/* container heading */}
      <div className="screen-heading">
        <span>{props.title}</span>
      </div>

      {/* container sub-heading ][ checking if there is any subheading or not. */}
      {props.subHeading ? (
        <div className="screen-sub-heading">
          <span>{props.subHeading}</span>
        </div>
      ) : (
        <div></div>
      )}

      {/* heading and container separator */}
      <div className="heading-seperator">
        <div className="seperator-line"></div>
        <div className="seperator-blob">
          <div></div>
        </div>
      </div>
      
    </div>
  );
}