import React from 'react';
import Main from './Main/Main';
import Header from './Header/Header';
import Footer from './Footer/Footer';
// import sample from "../../assets/Home/bg.mp4";
import './Home.css';

export default function Home(props) {
  return (
    <div className="home-container" id={props.id || ''}>
      <Header />
      <Main />
      <Footer />
    </div>
  );
}
